import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Validations from '../../utils/Validations';
import formInputStyle from '../../theme/styles/components/FormInput';
import { createCustomer } from '../../services/Customer';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInputStyle);

const CreateCustomer = ({ handleClose, handleRefreshList }) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setIsSubmitted(true);

    createCustomer(data)
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Created successfully.',
          type: 'success',
          show: true,
        });
        setIsSubmitted(false);
        handleClose();
        handleRefreshList();
      })
      .catch(({ response }) => {
        setIsSubmitted(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Create Customer</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <form id="create-customer" onSubmit={handleSubmit(onSubmit)}>
            <FormControl className={classes.formInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="firstName"
                id="firstName"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="First Name"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="text"
                    autoFocus
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="lastName"
                id="lastName"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Last Name"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="text"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_EMAIL}
                name="customerEmail"
                id="customerEmail"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Email"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="email"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="password"
                id="password"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                  />
                )}
              />
            </FormControl>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="create-customer"
          variant="contained"
          color="secondary"
          disabled={isSubmitted}
        >
          Create
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

CreateCustomer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleRefreshList: PropTypes.func.isRequired,
};

export default CreateCustomer;
