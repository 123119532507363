// Style for no project section
const Style = (theme) => ({
  root: {
    marginTop: 100,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
      marginBottom: 50,
    },
  },
  icon: {
    fontSize: '6rem',
  },
  errorContent: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default Style;
