import ProjectColors from '../../../config/ProjectColors';

// Style for project form
const Style = (theme) => ({
  formText: {
    marginBottom: 20,
  },
  formInput: {
    marginBottom: 15,
    width: '100%',
  },
  colorsContainer: {
    marginBottom: 15,
  },
  subscriptionContainer: {
    marginTop: 40,
  },
  btnColor1: {
    backgroundColor: ProjectColors.COLOR1,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR1,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR1,
    },
  },
  btnColor2: {
    backgroundColor: ProjectColors.COLOR2,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR2,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR2,
    },
  },
  btnColor3: {
    backgroundColor: ProjectColors.COLOR3,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR3,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR3,
    },
  },
  btnColor4: {
    backgroundColor: ProjectColors.COLOR4,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR4,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR4,
    },
  },
  btnColor5: {
    backgroundColor: ProjectColors.COLOR5,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR5,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR5,
    },
  },
  btnColor6: {
    backgroundColor: ProjectColors.COLOR6,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR6,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR6,
    },
  },
  btnColor7: {
    backgroundColor: ProjectColors.COLOR7,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR7,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR7,
    },
  },
  btnColor8: {
    backgroundColor: ProjectColors.COLOR8,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR8,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR8,
    },
  },
  btnColor9: {
    backgroundColor: ProjectColors.COLOR9,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR9,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR9,
    },
  },
  btnColor10: {
    backgroundColor: ProjectColors.COLOR10,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR10,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR10,
    },
  },
  btnColor11: {
    backgroundColor: ProjectColors.COLOR11,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR11,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR11,
    },
  },
  btnColor12: {
    backgroundColor: ProjectColors.COLOR12,
    color: 'white',
    minHeight: 35,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 40,
    },
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: ProjectColors.COLOR12,
    },
    '&:focus': {
      backgroundColor: ProjectColors.COLOR12,
    },
  },
});

export default Style;
