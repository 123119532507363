import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const ConfirmDialog = ({
  title,
  message,
  approveButtonText,
  onClose,
  onApprove,
  approveProcessing,
  approveType,
}) => (
  <Dialog open>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onApprove}
        autoFocus
        endIcon={approveProcessing && <CircularProgress size={25} />}
        disabled={approveProcessing}
        color={approveType === 'danger' ? 'secondary' : 'primary'}
        variant="contained"
      >
        {approveButtonText}
      </Button>
      <Button onClick={onClose} variant="contained">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  approveButtonText: PropTypes.string,
  approveProcessing: PropTypes.bool,
  approveType: PropTypes.oneOf(['danger', 'info']),
};

ConfirmDialog.defaultProps = {
  approveButtonText: 'Delete',
  approveProcessing: false,
  approveType: 'danger',
};
