import React from 'react';
import Grid from '@material-ui/core/Grid';
import Profile from '../../components/account/Profile';
import ChangePassword from '../../components/account/ChangePassword';
import Main from '../../layout/Main';

const Account = () => {
  const pageHeading = 'Account';

  return (
    <Main pageHeading={pageHeading}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Profile />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChangePassword />
        </Grid>
      </Grid>
    </Main>
  );
};

export default Account;
