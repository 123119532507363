// Style for account section
const Style = () => ({
  formContainer: {
    padding: 15,
    marginTop: 10,
  },
  formHeader: {
    marginBottom: 15,
  },
  formInput: {
    marginBottom: 15,
    width: '100%',
  },
  formSubmitBtn: {
    marginTop: 10,
  },
  infoText: {
    marginBottom: 15,
  },
});

export default Style;
