// Application route path information
const RoutePaths = {
  HOME: '/',
  CUSTOMERS: '/customers',
  PROJECTS: '/projects',
  USERS: '/users',
  REPORTS_BILLING: '/reports/billing',
  REPORTS_ACTIVITY: '/reports/activity',
  ACCOUNT: '/account',
  LOGOUT: '/logout',
};

export default RoutePaths;
