import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from 'react-router';
import Validations from '../../utils/Validations';
import accountStyle from '../../theme/styles/components/AccountSection';
import SnackbarMessage from '../SnackbarMessage';
import { reauthenticateUser, updatePassword } from '../../services/Auth';

const useStyles = makeStyles(accountStyle);

// Change password section
const ChangePassword = () => {
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, control, reset } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const onSubmit = (data) => {
    setSuccessMessage(null);
    setErrorMessage(null);
    if (data.newPassword1 === data.newPassword2) {
      reauthenticateUser(data.currentPassword)
        .then(() => {
          updatePassword(data.newPassword1)
            .then(() => {
              setSuccessMessage('Your password has been successfully updated.');
              reset();
              history.push('/logout');
            })
            .catch(() =>
              setErrorMessage('Some error occurred while updating the password, please try again.')
            );
        })
        .catch(() => {
          setErrorMessage(
            'Your current password does not match with our database, please enter correct password.'
          );
        });
    } else {
      setErrorMessage('The new password and Repeat password do not match.');
    }
  };

  return (
    <div className={classes.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="currentPassword"
            id="currentPassword"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Current Password"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="password"
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="newPassword1"
            id="newPassword1"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="New Password"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="password"
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="newPassword2"
            id="newPassword2"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Re-enter New Password"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="password"
              />
            )}
          />
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.formSubmitBtn}
        >
          Change Password
        </Button>
      </form>
      {errorMessage && <SnackbarMessage type="error" message={errorMessage} />}
      {successMessage && <SnackbarMessage type="success" message={successMessage} />}
    </div>
  );
};

export default ChangePassword;
