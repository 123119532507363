// Style for menubar
const Style = () => ({
  appbar: {
    boxShadow: 'none',
  },
  toolbar: {
    marginBottom: 50,
  },
  title: {
    flexGrow: 1,
    textTransform: 'uppercase',
  },
  menuButton: {
    marginRight: 5,
  },
});

export default Style;
