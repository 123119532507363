import grey from '@material-ui/core/colors/grey';

// Style for popup
const Style = (theme) => ({
  formText: {
    marginBottom: 20,
  },
  formInput: {
    marginBottom: 15,
    width: '100%',
  },
  rowItem: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  rowActionContainer: {
    right: 15,
  },
  rowActionButton: {
    padding: 5,
    marginLeft: 5,
  },
  closeButton: {
    position: 'absolute',
    padding: 6,
    right: 12,
    top: 12,
    color: theme.palette.grey[500],
  },
  tabs: {
    backgroundColor: grey[100],
  },
  tabsContainer: {
    fontSize: '.8em',
  },
  videoPlayer: {
    width: '100%',
    position: 'relative',
    backgroundColor: 'black',
  },
  markerBtn: {
    marginLeft: 10,
    marginRight: 10,
  },
});

export default Style;
