// Style for collaborator section
const Style = (theme) => ({
  addNewUserBtn: {
    marginLeft: 'auto',
    display: 'block',
  },

  formContainer: {
    display: 'flex',
    marginTop: 0,
    marginBottom: 10,
  },
  filterLeft: {
    width: '80%',
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  filterRight: {
    width: '30%',
    float: 'left',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  formInput: {
    marginBottom: 15,
    marginRight: 15,
    width: '100%',
  },
  colUser: {
    width: '30%',
    borderBottom: 0,
  },
  colRole: {
    width: '20%',
    borderBottom: 0,
    padding: 10,
  },
});

export default Style;
