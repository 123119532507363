import React from 'react';
import Main from '../../layout/Main';

const Users = () => {
  const pageHeading = 'Users';

  return (
    <Main pageHeading={pageHeading}>
      <h1>{pageHeading}</h1>
    </Main>
  );
};

export default Users;
