import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

/* google font css @imports are in /fonts/fonts.css */
const customFonts = `"Zen Kaku Gothic New",Arial,sans-serif`;

// Extend default MuiTheme
const Default = createMuiTheme({
  typography: {
    fontFamily: customFonts,
  },
  palette: {
    background: {
      default: grey[100],
    },
    primary: {
      main: grey[900],
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        color: 'black',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default Default;
