// Convert name into initials for avtar
const getInitials = (val) => {
  let returnValue = '';

  try {
    returnValue = val
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  } catch (e) {
    // Something went wrong while getting values
  }

  return returnValue;
};

export default getInitials;
