import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { AgGridReact } from 'ag-grid-react';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import Main from '../../layout/Main';
import NoCustomer from '../../components/customers/NoCustomer';
import pageStyle from '../../theme/styles/pages/Projects';
import ConfirmDialog from '../../components/ConfirmDialog';
import { getCustomers, updateAccountStatus } from '../../services/Customer';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import CreateCustomer from '../../components/customers/CreateCustomer';
import SnackbarMessage from '../../components/SnackbarMessage';

const useStyles = makeStyles(pageStyle);

const AccountStatusButton = ({ openAccountStatusConfirmation, data }) => (
  <IconButton
    onClick={() => openAccountStatusConfirmation(data)}
    variant="outlined"
    color="primary"
    size="small"
  >
    {data.disabled ? (
      <Tooltip title="Activate User">
        <ToggleOff color="secondary" />
      </Tooltip>
    ) : (
      <Tooltip title="Disable User">
        <ToggleOn />
      </Tooltip>
    )}
  </IconButton>
);

AccountStatusButton.propTypes = {
  openAccountStatusConfirmation: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Customers = () => {
  const pageHeading = 'Customers';
  const activateAccoutText = {
    title: 'Activate account?',
    message: 'Are you sure want to activate account of this customer ?',
    btnText: 'Activate',
  };
  const disableAccoutText = {
    title: 'Disable account?',
    message: 'Are you sure want to disable account of this customer ?',
    btnText: 'Disable',
  };

  const classes = useStyles();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [refershList, setRefreshList] = useState(false);

  const [openAddNewForm, setOpenAddNewForm] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleNewCustomer = () => {
    setOpenAddNewForm(true);
  };
  const handleRefreshList = () => {
    setRefreshList(!refershList);
  };

  const openAccountStatusConfirmation = (customer) => {
    setSelectedCustomer(customer);
    setShowDeleteConfirmation(true);
  };

  const closeAccountStatusConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedCustomer(null);
  };

  const handleCustomerStatus = async () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    updateAccountStatus({ uid: selectedCustomer.id, status: !selectedCustomer.disabled })
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Customer status updated successfully.',
          type: 'success',
          show: true,
        });
        setProcessing(false);
        setShowDeleteConfirmation(false);
        setRefreshList(!refershList);
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  const columnDefs = [
    {
      headerName: 'Customer ID',
      field: 'id',
    },
    {
      headerName: 'Customer Name',
      field: 'displayName',
    },
    {
      headerName: 'Customer Email',
      field: 'email',
    },
    {
      headerName: 'Account Status',
      field: 'accountStatus',
      cellRenderer: 'AccountStatus',
    },
    {
      headerName: 'Actions',
      cellRenderer: 'AccountStatusButton',
      cellRendererParams: { openAccountStatusConfirmation },
    },
  ];

  useEffect(() => {
    getCustomers()
      .then((data) => {
        setDataLoaded(true);
        setRows(data);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [refershList]);

  return (
    <Main pageHeading={pageHeading}>
      <div className={classes.header}>
        <div className={classes.addNewProjectBtn}>
          <Button color="primary" variant="contained" onClick={handleNewCustomer}>
            New Customer
          </Button>
        </div>
      </div>
      {dataLoaded && rows.length === 0 && <NoCustomer handleNewCustomer={handleNewCustomer} />}
      {dataLoaded && rows.length > 0 && (
        <Container className={classes.wrapper} maxWidth="xl">
          <div
            style={{
              height: '800px',
              width: '100%',
            }}
            className="ag-theme-material"
          >
            <AgGridReact
              defaultColDef={{
                flex: 1,
                resizable: true,
                sortable: true,
              }}
              autoGroupColumnDef={{
                flex: 1,
                minWidth: 280,
              }}
              rowData={rows}
              columnDefs={columnDefs}
              frameworkComponents={{ AccountStatusButton }}
            />
          </div>
        </Container>
      )}
      {openAddNewForm && (
        <CreateCustomer
          handleClose={() => setOpenAddNewForm(false)}
          handleRefreshList={handleRefreshList}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmDialog
          title={selectedCustomer.disabled ? activateAccoutText.title : disableAccoutText.title}
          message={
            selectedCustomer.disabled ? activateAccoutText.message : disableAccoutText.message
          }
          approveButtonText={
            selectedCustomer.disabled ? activateAccoutText.btnText : disableAccoutText.btnText
          }
          approveType={selectedCustomer.disabled ? 'info' : 'danger'}
          onClose={closeAccountStatusConfirmation}
          onApprove={handleCustomerStatus}
          approveProcessing={processing}
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Main>
  );
};

export default Customers;
