// Style for sidebar
const Style = () => ({
  logoContainer: {
    width: 260,
    textAlign: 'center',
    padding: 10,
  },
  logo: {
    height: 40,
  },
  tableGroup: {
    width: 260,
    position: 'relative',
    marginBottom: 20,
  },
  listItem: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  itemActionContainer: {
    right: 15,
  },
  actionButton: {
    padding: 5,
  },
  actionAddButton: {
    position: 'absolute',
    right: 5,
    top: 8,
    padding: 5,
  },
});

export default Style;
