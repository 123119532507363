import { green } from '@material-ui/core/colors';

// Style for projects page
const Style = () => ({
  wrapper: {
    minHeight: '50vh',
  },
  header: {
    marginBottom: 60,
  },
  addNewProjectBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    '& button': {
      fontWeight: '600',
    },
  },
  headerText: {
    color: 'inherit',
    fontWeight: '600',
  },
  activeColor: {
    background: green[500],
    color: '#FFF',
  },
});

export default Style;
