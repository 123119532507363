import React from 'react';
import Main from '../../layout/Main';

const NotFound = () => {
  const pageHeading = '404';

  return (
    <Main pageHeading={pageHeading}>
      <h1>{pageHeading}</h1>
    </Main>
  );
};

export default NotFound;
