import axios from 'axios';
import { db } from '../config/Firebase';
import UserTypes from '../config/UserTypes';

export const getCustomers = async () => {
  const rows = [];
  await db
    .collection('users')
    .where('role', '==', 'customer')
    .get()
    .then((snap) => {
      snap.forEach((doc) => {
        const tempData = doc.data();
        rows.push({
          id: doc.id,
          accountStatus: tempData.disabled ? 'Disabled' : 'Active',
          ...tempData,
        });
      });
    });
  return rows;
};

export const createCustomer = async (data) => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  const initReq = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/customer/create`,
    data,
    withCredentials: true,
  });

  return initReq;
};

export const updateAccountStatus = async ({ uid, status }) => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  const initReq = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/customer/update-status`,
    data: { uid, status },
    withCredentials: true,
  });

  return initReq;
};

// Get customer email list
export const getCustomersEmailList = async () => {
  const activeCustomersSnap = await db
    .collection('users')
    .where('role', '==', UserTypes.CUSTOMER.value)
    .where('disabled', '==', false)
    .get();

  const rows = [];

  activeCustomersSnap.forEach((doc) => {
    rows.push({
      id: doc.id,
      email: doc.data().email,
    });
  });

  return rows || [];
};
