import React from 'react';
import Main from '../../layout/Main';

const Billing = () => {
  const pageHeading = 'Billing';

  return (
    <Main pageHeading={pageHeading}>
      <h1>{pageHeading}</h1>
    </Main>
  );
};

export default Billing;
