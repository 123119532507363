// Style for main layout
const Style = () => ({
  wrapper: {
    minHeight: '50vh',
  },
  innerWrapper: {
    position: 'relative',
    top: -50,
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.5)',
  },
});

export default Style;
