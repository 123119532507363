/* eslint-disable import/no-cycle */
import { db } from '../config/Firebase';

// Get user list
export const getUserShortList = async () => {
  const rows = {};

  const usersSnap = await db.collection('users').get();
  usersSnap.forEach((doc) => {
    rows[doc.id] = doc.data().displayName;
  });

  return rows;
};

// Get single user details
export const getUserDetails = async (id) => {
  const user = await db.collection('users').doc(id).get();
  return { ...user.data(), id };
};
