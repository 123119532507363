import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from './UserMenu';
import menubarStyle from '../../theme/styles/components/Menubar';
import Sidebar from './Sidebar';

const useStyles = makeStyles(menubarStyle);

// Menubar for main layout
const Menubar = ({ pageHeading }) => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {pageHeading}
          </Typography>
          <UserMenu />
        </Toolbar>
      </AppBar>
      {sidebarOpen && <Sidebar sidebarOpen={sidebarOpen} handleSidebar={handleSidebar} />}
    </>
  );
};

Menubar.propTypes = {
  pageHeading: PropTypes.string.isRequired,
};

export default Menubar;
