// Style for user menu
const Style = () => ({
  userMenu: {
    padding: 5,
    '& .MuiIconButton-label': {
      fontSize: '1rem',
    },
    '& .MuiSvgIcon-root': {
      marginRight: 5,
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  userOptions: {
    '& .MuiList-root': {
      width: 160,
    },
  },
});

export default Style;
