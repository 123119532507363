import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LicenseManager } from 'ag-grid-enterprise';
import { ThemeProvider } from '@material-ui/core/styles';
import RoutePaths from '../config/Routes';
import Account from './account/Account';
import Logout from './account/Logout';
import Customers from './customers/Customers';
import Users from './users/Users';
import Projects from './projects/Projects';
import ActivityReport from './reports/Activity';
import BillingReport from './reports/Billing';
import NotFound from './errors/NotFound';
import AppLoader from '../components/AppLoader';
import Default from '../theme/Default';
import { checkAuthState } from '../services/Auth';
import '../theme/fonts/fonts.css';

// Configure ag grid enterprise features
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_LICENSE_KEY);

// Initialize app routes
const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    checkAuthState().then((res) => {
      if (!res.data.customToken) {
        window.location.href = process.env.REACT_APP_AUTH_BASE_URL;
      }
      setAppLoaded(true);
    });
  }, []);

  return (
    <ThemeProvider theme={Default}>
      <CssBaseline />

      {!appLoaded && <AppLoader />}

      {appLoaded && (
        <Router>
          <Switch>
            <Route path={RoutePaths.HOME} exact component={Customers} />
            <Route path={RoutePaths.CUSTOMERS} exact component={Customers} />
            <Route path={RoutePaths.PROJECTS} exact component={Projects} />
            <Route path={RoutePaths.USERS} exact component={Users} />
            <Route path={RoutePaths.REPORTS_BILLING} exact component={BillingReport} />
            <Route path={RoutePaths.REPORTS_ACTIVITY} exact component={ActivityReport} />
            <Route path={RoutePaths.ACCOUNT} exact component={Account} />
            <Route path={RoutePaths.LOGOUT} exact component={Logout} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
