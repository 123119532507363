import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { signoutUser } from '../../services/Auth';
import Empty from '../../layout/Empty';
import pageStyle from '../../theme/styles/pages/Logout';

const useStyles = makeStyles(pageStyle);

const Logout = () => {
  const classes = useStyles();
  const pageHeading = 'Logout';

  useEffect(() => {
    signoutUser()
      .then(() => {
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_AUTH_BASE_URL;
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;
        }, 500);
      });
  }, []);

  return (
    <Empty pageHeading={pageHeading}>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <CircularProgress color="inherit" />

          <Typography component="p" className={classes.pageText}>
            Loging out...
          </Typography>
        </Container>
      </Grid>
    </Empty>
  );
};

export default Logout;
