import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Firebase configurations
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize firebase app
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize firebase services
const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

// Connect services with emulator
if (window.location.hostname === 'localhost') {
  auth.useEmulator('http://localhost:9098');
  db.useEmulator('localhost', 8081);
}

export { auth, db };
