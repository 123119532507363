import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AppsIcon from '@material-ui/icons/Apps';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../../assets/images/logo.svg';
import sidebarStyle from '../../theme/styles/components/Sidebar';
import Routes from '../../config/Routes';

const useStyles = makeStyles(sidebarStyle);

// Sidebar for main layout
const Sidebar = ({ sidebarOpen, handleSidebar }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClose = () => {
    handleSidebar();
  };

  const handleLink = (page) => {
    history.push(page);
    handleSidebar();
  };

  return (
    <>
      <Drawer
        BackdropProps={{ invisible: true }}
        open={sidebarOpen}
        onClose={handleClose}
        className={classes.tableDrawer}
      >
        <div className={classes.logoContainer}>
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
        </div>

        <List>
          <ListItem
            button
            onClick={() => handleLink(Routes.CUSTOMERS)}
            selected={location.pathname === Routes.CUSTOMERS || location.pathname === Routes.HOME}
          >
            <ListItemIcon className={classes.menuIcon}>
              <GroupIcon color="primary" />
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary="Customers" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleLink(Routes.PROJECTS)}
            selected={location.pathname === Routes.PROJECTS}
          >
            <ListItemIcon className={classes.menuIcon}>
              <AppsIcon color="primary" />
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary="Projects" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleLink(Routes.ACCOUNT)}
            selected={location.pathname === Routes.ACCOUNT}
          >
            <ListItemIcon className={classes.menuIcon}>
              <AccountBoxIcon color="primary" />
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary="My Account" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleLink(Routes.LOGOUT)}
            selected={location.pathname === Routes.LOGOUT}
          >
            <ListItemIcon className={classes.menuIcon}>
              <ExitToAppIcon color="primary" />
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  handleSidebar: PropTypes.func.isRequired,
};

export default Sidebar;
