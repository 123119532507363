/* eslint-disable no-async-promise-executor */

import firebase from 'firebase';
import { db } from '../config/Firebase';
import { getUserDetails, getUserShortList } from './User';
import Roles from '../config/Roles';
// Create new project
export const createNewProject = async (data) => {
  await db.collection('projects').add({
    name: data.name,
    color: data.color,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    ownedBy: null,
    accessWith: [],
    accessDetails: [],
    status: 'Draft',
  });
};

// Get list of my projects
export const getMyProjects = async () => {
  const rows = [];

  const usersList = await getUserShortList();

  const projectsSnap = await db.collection('projects').orderBy('createdAt', 'desc').get();

  projectsSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
      ownerName: usersList[tempData.ownedBy],
      totalUsers: tempData.accessDetails.length,
    });
  });

  return rows;
};

// Update project name and color
export const editProject = async (id, data) => {
  await db.collection('projects').doc(id).set(
    {
      name: data.name,
      color: data.color,
    },
    { merge: true }
  );
};

export const deleteProject = async (id) => {
  await db.collection('projects').doc(id).delete();
};

export const editProjectConfigs = async (id, data) => {
  await db.collection('projects').doc(id).set(data, { merge: true });
};

// Get single project details
export const getProjectDetails = async (id) => {
  const project = await db.collection('projects').doc(id).get();
  return project.data();
};

// Get project users information
export const getProjectUsersInfo = async (id) => {
  const project = await db.collection('projects').doc(id).get();
  if (!project.exists) {
    return [];
  }

  const finalData = [...project.data().accessDetails];
  const promises = [];
  finalData.forEach(async (col) => {
    const promise = new Promise(async (resolve) => {
      const data = col;
      const getDetails = await getUserDetails(col.id);
      data.email = getDetails.email;
      data.name = getDetails.displayName;
      resolve(data);
    });
    promises.push(promise);
  });
  const rows = await Promise.all(promises);
  return rows;
};

export const updateProjectUser = async (projectId, { userId, role }) => {
  const project = await db.collection('projects').doc(projectId).get();
  const projectData = project.data();

  const accessDetailsData = [...projectData.accessDetails];
  const accessWith = [...projectData.accessWith];
  const doesExist = accessDetailsData.find((k) => k.id === userId);

  // only update role if already exist
  if (doesExist) {
    const ownerExists = accessDetailsData.find((k) => k.role === Roles.OWNER.value);

    if (role === Roles.OWNER.value && ownerExists && ownerExists.id !== userId) {
      throw new Error('Owner already exist.');
    }

    accessDetailsData[accessDetailsData.indexOf(doesExist)].role = role;
  } else {
    accessDetailsData.push({ role, id: userId });
    accessWith.push(userId);
  }

  const updateData = {
    accessDetails: accessDetailsData,
    accessWith,
  };
  if (role === Roles.OWNER.value) {
    updateData.ownedBy = userId;
  }
  await project.ref.update(updateData);
  return true;
};

export const deleteProjectUser = async (projectId, userId) => {
  const project = await db.collection('projects').doc(projectId).get();
  const projectData = project.data();

  const accessDetailsData = [...projectData.accessDetails];
  const accessWith = [...projectData.accessWith];
  const doesExist = accessDetailsData.find((k) => k.id === userId);

  let newAccessDetailsData = [];
  let newAccessWithData = [];

  //  delete user if  exist
  if (doesExist) {
    newAccessDetailsData = accessDetailsData.filter((k) => k.id !== userId);
    newAccessWithData = accessWith.filter((k) => k !== userId);
    await project.ref.update({
      accessDetails: newAccessDetailsData,
      accessWith: newAccessWithData,
    });
    return true;
  }
  throw new Error('No such user exist.');
};
