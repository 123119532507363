// Project color constants
const ProjectColors = {
  COLOR1: '#b71c1c',
  COLOR2: '#880e4f',
  COLOR3: '#4a148c',
  COLOR4: '#1a237e',
  COLOR5: '#0d47a1',
  COLOR6: '#1b5e20',
  COLOR7: '#e65100',
  COLOR8: '#424242',
  COLOR9: '#f57f17',
  COLOR10: '#3e2723',
  COLOR11: '#827717',
  COLOR12: '#0277bd',
};

export default ProjectColors;
