import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import wrapperStyle from '../theme/styles/layout/Main';
import Menubar from '../components/layout/Menubar';

const useStyles = makeStyles(wrapperStyle);

// Layout for main layout
const Main = ({ children, pageHeading }) => {
  const classes = useStyles();

  useEffect(() => {
    if (pageHeading) {
      window.document.title = pageHeading;
    } else {
      window.document.title = `${process.env.REACT_APP_NAME} - Application`;
    }
  }, []);

  return (
    <>
      <Menubar pageHeading={pageHeading} />
      <Container className={classes.wrapper} maxWidth="xl">
        <div className={classes.innerWrapper}>{children}</div>
      </Container>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.element.isRequired,
  pageHeading: PropTypes.string.isRequired,
};

export default Main;
