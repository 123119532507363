import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { AgGridReact } from 'ag-grid-react';
import IconButton from '@material-ui/core/IconButton';
import { DeleteOutline, EditOutlined, Settings } from '@material-ui/icons';
import { Chip, Tooltip } from '@material-ui/core';
import NoProject from '../../components/projects/NoProject';
import { deleteProject, getMyProjects } from '../../services/Project';
import CreateProject from '../../components/projects/CreateProject';
import UpdateProject from '../../components/projects/UpdateProject';
import pageStyle from '../../theme/styles/pages/Projects';
import Main from '../../layout/Main';
import ConfirmDialog from '../../components/ConfirmDialog';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ManageProjectConfigs from '../../components/projects/ManageProjectConfigs';
import SnackbarMessage from '../../components/SnackbarMessage';
import ProjectUsers from '../../components/projects/ProjectUsers';

const useStyles = makeStyles(pageStyle);

const ProjectActions = ({
  handleEditProject,
  openProjectSettings,
  openDeleteConfirmation,
  data,
}) => (
  <>
    <IconButton
      onClick={() => handleEditProject(data)}
      variant="outlined"
      color="primary"
      size="small"
    >
      <Tooltip title="Edit">
        <EditOutlined />
      </Tooltip>
    </IconButton>
    <IconButton
      onClick={() => openProjectSettings(data)}
      variant="outlined"
      color="primary"
      size="small"
    >
      <Tooltip title="Setting">
        <Settings />
      </Tooltip>
    </IconButton>
    <IconButton
      onClick={() => openDeleteConfirmation(data)}
      variant="outlined"
      color="secondary"
      size="small"
    >
      <Tooltip title="Delete">
        <DeleteOutline />
      </Tooltip>
    </IconButton>
  </>
);

ProjectActions.propTypes = {
  handleEditProject: PropTypes.func.isRequired,
  openProjectSettings: PropTypes.func.isRequired,
  openDeleteConfirmation: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const statusCellRenderer = ({ data, classes }) => (
  <Chip
    label={data.status}
    size="small"
    className={data.status === 'Active' ? classes.activeColor : ''}
  />
);

const Projects = () => {
  const classes = useStyles();
  const pageHeading = 'Projects';

  const [dataLoaded, setDataLoaded] = useState(false);
  const [refershList, setRefreshList] = useState(false);
  const [rows, setRows] = useState([]);

  const [openAddNewForm, setOpenAddNewForm] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openSettingForm, setOpenSettingForm] = useState(false);
  const [openProjectCongfigsForm, setOpenProjectCongfigsForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleNewProject = () => {
    setOpenAddNewForm(!openAddNewForm);
  };

  const handleRefreshList = () => {
    setDataLoaded(false);
    setOpenAddNewForm(false);
    setRefreshList(!refershList);
  };

  const openProjectSettings = (project) => {
    setSelectedProject(project);
    setOpenSettingForm(true);
  };

  const closeProjectSettings = () => {
    setOpenSettingForm(false);
    setSelectedProject(null);
  };

  const openDeleteConfirmation = (project) => {
    setSelectedProject(project);
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedProject(null);
  };

  const handleEditProject = (project) => {
    setSelectedProject(project);
    setOpenProjectCongfigsForm(true);
  };

  const handleDeleteProject = async () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    deleteProject(selectedProject.id)
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Deleted successfully.',
          type: 'success',
          show: true,
        });
        setProcessing(false);
        setShowDeleteConfirmation(false);
        setRefreshList(!refershList);
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  const columnDefs = [
    {
      headerName: 'Project ID',
      field: 'id',
    },
    {
      headerName: 'Project',
      field: 'name',
    },
    {
      headerName: 'Users',
      field: 'totalUsers',
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusCellRenderer',
      cellRendererParams: { classes },
    },
    {
      headerName: 'Actions',
      cellRenderer: 'ProjectActions',
      cellRendererParams: { handleEditProject, openProjectSettings, openDeleteConfirmation },
    },
  ];

  const showProjectUsers = (project) => {
    setSelectedProject(project);
    setOpenUsersDialog(true);
  };

  const closeProjectUserDialog = () => {
    setSelectedProject(null);
    setOpenUsersDialog(false);
    setRefreshList(!refershList);
  };

  const handleCellClicked = (e) => {
    const projectData = e.data;
    const clickedColumnField = e?.column?.colId;
    switch (clickedColumnField) {
      case 'totalUsers':
        showProjectUsers(projectData);
        break;

      default:
    }
  };

  useEffect(() => {
    getMyProjects()
      .then((data) => {
        setDataLoaded(true);
        setRows(data);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [refershList]);

  return (
    <Main pageHeading={pageHeading}>
      <div className={classes.header}>
        <div className={classes.addNewProjectBtn}>
          <Button color="primary" variant="contained" onClick={handleNewProject}>
            New Project
          </Button>
        </div>
      </div>
      {dataLoaded && rows.length === 0 && <NoProject handleNewProject={handleNewProject} />}

      {dataLoaded && rows.length > 0 && (
        <Container className={classes.wrapper} maxWidth="xl">
          <div
            style={{
              height: '800px',
              width: '100%',
            }}
            className="ag-theme-material"
          >
            <AgGridReact
              defaultColDef={{
                flex: 1,
                resizable: true,
                sortable: true,
              }}
              autoGroupColumnDef={{
                flex: 1,
                minWidth: 280,
              }}
              rowData={rows}
              columnDefs={columnDefs}
              onCellClicked={handleCellClicked}
              frameworkComponents={{ ProjectActions, statusCellRenderer }}
            />
          </div>
        </Container>
      )}
      {openAddNewForm && (
        <CreateProject handleNewProject={handleNewProject} handleRefreshList={handleRefreshList} />
      )}
      {openSettingForm && (
        <ManageProjectConfigs
          handleClose={() => {
            closeProjectSettings();
            handleRefreshList();
          }}
          projectId={selectedProject.id}
        />
      )}
      {openProjectCongfigsForm && (
        <UpdateProject
          closeProjectSettings={() => {
            setOpenProjectCongfigsForm(false);
          }}
          handleRefreshList={handleRefreshList}
          id={selectedProject.id}
          name={selectedProject.name}
          color={selectedProject.color}
          status={selectedProject.status}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmDialog
          title="Delete Project"
          message="Are you sure you want to delete this project? This action can not be undone."
          onClose={closeDeleteConfirmation}
          onApprove={handleDeleteProject}
          approveProcessing={processing}
        />
      )}

      {openUsersDialog && (
        <ProjectUsers handleClose={closeProjectUserDialog} projId={selectedProject.id} />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Main>
  );
};

export default Projects;
