const setupStatus = (setupData) => {
  let isCompleted = true;

  if (setupData) {
    Object.keys(setupData).forEach((k) => {
      if (!setupData[k]) {
        isCompleted = false;
      }
    });

    return isCompleted;
  }
  return false;
};
export default setupStatus;
