import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography, FormControl, Chip, Avatar } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';
import formInputStyle from '../../theme/styles/components/FormInput';
import Validations from '../../utils/Validations';
import setupStatus from '../../utils/SetupStatus';
import { editProjectConfigs, getProjectDetails } from '../../services/Project';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInputStyle);

const ManageProjectConfigs = ({ projectId, handleClose }) => {
  const { handleSubmit, control, setValue, getValues } = useForm();

  const [isFirebaseSetup, setIsFirebaseSetup] = useState(false);
  const [isAwsSetup, setIsAwsSetup] = useState(false);
  const [isBillingSetup, setIsBillingSetup] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    getProjectDetails(projectId).then((res) => {
      // Base configs
      setValue('name', res.name);
      setValue('status', res.status);

      // firebase configs
      const firebaseSteup = res.setup?.firebase?.data || null;
      setValue('setup.firebase.data.apiKey', firebaseSteup?.apiKey || '');
      setValue('setup.firebase.data.authDomain', firebaseSteup?.authDomain || '');
      setValue('setup.firebase.data.projectId', firebaseSteup?.projectId || '');
      setValue('setup.firebase.data.storageBucket', firebaseSteup?.storageBucket || '');
      setValue('setup.firebase.data.messagingSenderId', firebaseSteup?.messagingSenderId || '');
      setValue('setup.firebase.data.appId', firebaseSteup?.appId || '');
      setIsFirebaseSetup(res.setup?.firebase?.isSetup || false);

      // AWS configs
      const awsSetup = res.setup?.aws?.data || null;
      setValue('setup.aws.data.accountId', awsSetup?.accountId || '');
      setValue('setup.aws.data.accessKey', awsSetup?.accessKey || '');
      setValue('setup.aws.data.secretKey', awsSetup?.secretKey || '');
      setValue('setup.aws.data.mediaConvertedEndpoint', awsSetup?.mediaConvertedEndpoint || '');
      setValue('setup.aws.data.storageBucket', awsSetup?.storageBucket || '');
      setValue('setup.aws.data.storageBucketUrl', awsSetup?.storageBucketUrl || '');
      setIsAwsSetup(res.setup?.aws?.isSetup || false);

      // Billing configs
      const billingSetup = res.setup?.billing?.data || null;
      setValue('setup.billing.data.plan', billingSetup?.plan || 'NOT_PAID');
      setValue('setup.billing.data.mode', billingSetup?.mode || 'MONTHLY');
      setIsBillingSetup(res.setup?.billing?.isSetup || true);
    });
  }, [projectId]);

  const checkSetupStatus = () => {
    const data = getValues();
    const firebaseStatus = setupStatus(data.setup.firebase.data);
    const awsStatus = setupStatus(data.setup.aws.data);
    const billingStatus = setupStatus(data.setup.billing.data);
    setIsFirebaseSetup(firebaseStatus);
    setIsAwsSetup(awsStatus);
    setIsBillingSetup(billingStatus);
  };

  const onSubmit = async (data) => {
    const finalData = { ...data };

    const firebaseStatus = setupStatus(data.setup.firebase.data);
    const awsStatus = setupStatus(data.setup.aws.data);
    const billingStatus = setupStatus(data.setup.billing.data);

    finalData.setup.firebase.isSetup = firebaseStatus || false;
    finalData.setup.aws.isSetup = awsStatus || false;
    finalData.setup.billing.isSetup = billingStatus || false;

    editProjectConfigs(projectId, finalData)
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Updated successfully.',
          type: 'success',
          show: true,
        });
        handleClose();
      })
      .catch(({ response }) => {
        // something went wrong
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };
  return (
    <>
      <Dialog open maxWidth="md">
        <DialogTitle id="form-dialog-title">Edit Project Configurations</DialogTitle>
        <DialogContent dividers>
          <form id="project-config" onSubmit={handleSubmit(onSubmit)} onChange={checkSetupStatus}>
            <ProjectEssentialsFields
              control={control}
              isFirebaseSetup={isFirebaseSetup}
              isAwsSetup={isAwsSetup}
              isBillingSetup={isBillingSetup}
            />
            <FirebaseSetupFields control={control} isFirebaseSetup={isFirebaseSetup} />
            <AwsSetupFields control={control} isAwsSetup={isAwsSetup} />
            <BillingInformationFields control={control} isBillingSetup={isBillingSetup} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button form="project-config" type="submit" variant="contained" color="secondary">
            Save Changes
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Dialog>
    </>
  );
};

const ProjectEssentialsFields = ({ control, isFirebaseSetup, isAwsSetup, isBillingSetup }) => {
  const classes = useStyles();
  return (
    <div className="fields-container">
      <Typography variant="h6">Project Essentials</Typography>
      <Grid container spacing={1} alignItems="end">
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="name"
              id="name"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Project"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="status"
              id="status"
              defaultValue="DRAFT"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Status"
                  value={value}
                  disabled={!(isFirebaseSetup && isAwsSetup && isBillingSetup)}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="Draft">DRAFT</option>
                  <option value="Active">ACTIVE</option>
                </TextField>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

ProjectEssentialsFields.propTypes = {
  control: PropTypes.func.isRequired,
  isFirebaseSetup: PropTypes.bool.isRequired,
  isAwsSetup: PropTypes.bool.isRequired,
  isBillingSetup: PropTypes.bool.isRequired,
};

const FirebaseSetupFields = ({ control, isFirebaseSetup }) => {
  const classes = useStyles();
  return (
    <div className="fields-container">
      <Grid container justify="space-between">
        <Typography variant="h6">Firebase Setup</Typography>
        {isFirebaseSetup && <SetupCompleted />}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.firebase.data.apiKey"
              id="setup.firebase.data.apiKey"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="API key"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.firebase.data.authDomain"
              id="setup.firebase.data.authDomain"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Auth Domain"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.firebase.data.projectId"
              id="setup.firebase.data.projectId"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Project Id"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.firebase.data.storageBucket"
              id="setup.firebase.data.storageBucket"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Storage Bucket"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.firebase.data.messagingSenderId"
              id="setup.firebase.data.messagingSenderId"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Messaging Sender Id"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.firebase.data.appId"
              id="setup.firebase.data.appId"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="App Id"
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

FirebaseSetupFields.propTypes = {
  control: PropTypes.func.isRequired,
  isFirebaseSetup: PropTypes.bool.isRequired,
};

const AwsSetupFields = ({ control, isAwsSetup }) => {
  const classes = useStyles();
  return (
    <div className="fields-container">
      <Grid container justify="space-between">
        <Typography variant="h6">Aws Setup</Typography>
        {isAwsSetup && <SetupCompleted />}
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.aws.data.accountId"
              id="setup.aws.data.accountId"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Account Id"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.aws.data.accessKey"
              id="setup.aws.data.accessKey"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Access Key"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.aws.data.secretKey"
              id="setup.aws.data.secretKey"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Secret Key"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.aws.data.mediaConvertedEndpoint"
              id="setup.aws.data.mediaConvertedEndpoint"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Media Converted Endpoint"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.aws.data.storageBucket"
              id="setup.aws.data.storageBucket"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Storage Bucket"
                  type="text"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.aws.data.storageBucketUrl"
              id="setup.aws.data.storageBucketUrl"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Storage Bucket URL"
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

AwsSetupFields.propTypes = {
  control: PropTypes.func.isRequired,
  isAwsSetup: PropTypes.bool.isRequired,
};

const BillingInformationFields = ({ control, isBillingSetup }) => {
  const classes = useStyles();
  return (
    <div className="fields-container">
      <Grid container justify="space-between">
        <Typography variant="h6">Billing Information</Typography>
        {isBillingSetup && <SetupCompleted />}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.billing.data.plan"
              ids="setup.billing.data.plan"
              defaultValue="NOT_PAID"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  fullWidth
                  label="Plan"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="PAID">PAID</option>
                  <option value="NOT_PAID">NOT PAID</option>
                </TextField>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="setup.billing.data.mode"
              id="setup.billing.data.mode"
              defaultValue="MONTHLY"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  fullWidth
                  label="Billing Mode"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="MONTHLY">MONTHLY</option>
                  <option value="YEARLY">YEARLY</option>
                </TextField>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

BillingInformationFields.propTypes = {
  control: PropTypes.func.isRequired,
  isBillingSetup: PropTypes.bool.isRequired,
};

const SetupCompleted = () => (
  <Chip
    size="small"
    avatar={
      <Avatar color="secondary">
        <Done />
      </Avatar>
    }
    label="Completed"
    color="secondary"
  />
);

ManageProjectConfigs.propTypes = {
  projectId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ManageProjectConfigs;
