import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import wrapperStyle from '../theme/styles/layout/Empty';

const useStyles = makeStyles(wrapperStyle);

// Layout for error pages
const Empty = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.wrapper} maxWidth="xl">
      {children}
    </Container>
  );
};

Empty.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Empty;
