import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import noProjectStyle from '../../theme/styles/components/NoProject';

const useStyles = makeStyles(noProjectStyle);

// Display no project information
const NoCustomer = ({ handleNewCustomer }) => {
  const classes = useStyles();

  const handleAction = () => {
    handleNewCustomer();
  };

  return (
    <Grid container align="center" justify="center">
      <Grid item>
        <div className={classes.root}>
          <InfoOutlinedIcon color="primary" className={classes.icon} />
          <Typography component="p" className={classes.errorContent}>
            You haven&apos;t created any customer.
          </Typography>
          <Button color="primary" variant="contained" onClick={handleAction}>
            Create Customer
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

NoCustomer.propTypes = {
  handleNewCustomer: PropTypes.func.isRequired,
};

export default NoCustomer;
