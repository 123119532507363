import axios from 'axios';
import firebase from 'firebase';
import { auth, db } from '../config/Firebase';

// Check if user is signed in or not
export const checkAuthState = async () => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  const checkAuthApi = await axios({
    method: 'GET',
    url: `${apiEndpoint}/api/auth/validate-session`,
    withCredentials: true,
  });

  // Signin user with custom token if exists
  if (checkAuthApi.data.customToken) {
    await auth.signInWithCustomToken(checkAuthApi.data.customToken);
  } else {
    auth.signOut();
  }

  return checkAuthApi;
};

// Signout the user
export const signoutUser = async () => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  await axios({
    method: 'GET',
    url: `${apiEndpoint}/api/auth/signout`,
    withCredentials: true,
  });

  auth.signOut();
};

// update user profile
export const updateProfile = async (data) => {
  const { currentUser } = auth;
  await currentUser.updateProfile({ displayName: data.displayName });
  await db
    .doc(`users/${currentUser.uid}`)
    .update({ displayName: data.displayName, firstName: data.firstName, lastName: data.lastName });
};

// Reauthenticate the user
export const reauthenticateUser = (password) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    auth.currentUser.email,
    // eslint-disable-next-line comma-dangle
    password
  );
  return auth.currentUser.reauthenticateWithCredential(credential);
};

// Update the user password
export const updatePassword = (password) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  auth.currentUser.updatePassword(password);
