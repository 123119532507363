import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Avatar,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import projectUsersStyle from '../../theme/styles/components/projectUsers';
import { getProjectUsersInfo, updateProjectUser, deleteProjectUser } from '../../services/Project';
import Roles from '../../config/Roles';
import Validations from '../../utils/Validations';
import SnackBarMessage from '../SnackbarMessage';
import { getCustomersEmailList } from '../../services/Customer';
import getInitials from '../../utils/NameInitials';

const useStyles = makeStyles(projectUsersStyle);

// List project users
const ProjectUsers = ({ projId, handleClose }) => {
  const classes = useStyles();

  const [projectUsers, setProjectUsers] = useState([]);
  const [ownerExistInProject, setOwnerExistInProject] = useState(false);
  const [customerEmails, setCustomerEmails] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState({
    show: false,
    message: '',
    type: '',
  });

  const [emailError, setEmailError] = useState('');

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    setDataLoaded(false);

    getProjectUsersInfo(projId).then(async (res) => {
      setProjectUsers(res);
      const ownerData = res.filter((u) => u.role === Roles.OWNER.value);
      setOwnerExistInProject(ownerData.length > 0);
      setDataLoaded(true);
    });

    if (customerEmails.length === 0) {
      setDataLoaded(false);
      getCustomersEmailList().then((res) => {
        setCustomerEmails(res);
        setDataLoaded(true);
      });
    }
  }, [reloadData]);

  const onSubmit = async (data) => {
    setSubmitting(true);
    setSnackbarMessage({
      show: false,
      message: '',
      type: '',
    });

    try {
      await updateProjectUser(projId, {
        role: data.role,
        userId: data.user.id,
      });

      setSnackbarMessage({
        show: true,
        message: 'User added to project',
        type: 'success',
      });
      setSubmitting(false);
      setReloadData(!reloadData);
    } catch (e) {
      setSnackbarMessage({
        show: true,
        message: e.message || 'Error adding user',
        type: 'error',
      });
      setSubmitting(false);
    }
  };

  const updateRole = async (data, newRole) => {
    setSnackbarMessage({
      show: false,
      message: '',
      type: '',
    });

    try {
      await updateProjectUser(projId, { role: newRole, userId: data.id });

      setSnackbarMessage({
        show: true,
        message: 'Role updated successfully',
        type: 'success',
      });

      setReloadData(!reloadData);
    } catch (error) {
      setSnackbarMessage({
        show: true,
        message: error.message || 'Error updating user role',
        type: 'error',
      });
    }
  };

  const deleteUserFromProject = async (data) => {
    try {
      setSnackbarMessage({
        show: false,
        message: '',
        type: '',
      });

      await deleteProjectUser(projId, data.id);
      setSnackbarMessage({
        show: true,
        message: 'User removed from project',
        type: 'success',
      });
      setReloadData(!reloadData);
    } catch (e) {
      setSnackbarMessage({
        show: true,
        message: e.message || 'Error deleting user',
        type: 'error',
      });
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="project-users-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="project-users-dialog">Project Users</DialogTitle>
      <DialogContent dividers>
        {!dataLoaded ? (
          <Box justifyContent="center" display="flex">
            <CircularProgress size={25} />
          </Box>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.formContainer}>
                <div className={classes.filterLeft}>
                  <FormControl
                    className={classes.formInput}
                    style={emailError ? { marginTop: 20 } : null}
                  >
                    <Controller
                      control={control}
                      rules={Validations.REQUIRED}
                      name="user"
                      id="user"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          options={customerEmails || []}
                          defaultValue={null}
                          getOptionLabel={(option) => option.email}
                          renderInput={(opts) => (
                            <TextField
                              {...opts}
                              value={value}
                              error={!!error || emailError}
                              helperText={emailError || error?.message || null}
                              label="Email Address"
                            />
                          )}
                          onChange={(_, data) => {
                            setEmailError('');
                            const alreadyExist = projectUsers.filter(
                              (user) => user.email === data?.email
                            );
                            if (alreadyExist.length > 0) {
                              setEmailError('User already added.');
                            }
                            onChange(data);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className={classes.formInput}>
                    <Controller
                      control={control}
                      name="role"
                      id="role"
                      size="small"
                      rules={Validations.REQUIRED}
                      defaultValue={ownerExistInProject ? Roles.EDITOR.value : Roles.OWNER.value}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <InputLabel htmlFor="access">Access</InputLabel>
                          <Select id="role" name="role" value={value} onChange={onChange}>
                            {!ownerExistInProject && (
                              <MenuItem value={Roles.OWNER.value}>{Roles.OWNER.text}</MenuItem>
                            )}
                            {ownerExistInProject && (
                              <MenuItem value={Roles.EDITOR.value}>{Roles.EDITOR.text}</MenuItem>
                            )}
                            {ownerExistInProject && (
                              <MenuItem value={Roles.READ_ONLY.value}>
                                {Roles.READ_ONLY.text}
                              </MenuItem>
                            )}
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
                <div className={classes.filterRight}>
                  <Button
                    className={classes.addNewUserBtn}
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={submitting || !!emailError}
                  >
                    Add User
                  </Button>
                </div>
              </div>
            </form>

            <ListProjectUsers
              projectUsers={projectUsers}
              updateRole={updateRole}
              deleteUserFromProject={deleteUserFromProject}
              ownerExistInProject={ownerExistInProject}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
      {snackBarMessage.show && <SnackBarMessage {...snackBarMessage} />}
    </Dialog>
  );
};

const ListProjectUsers = ({
  projectUsers,
  updateRole,
  deleteUserFromProject,
  ownerExistInProject,
}) => {
  const classes = useStyles();

  return (
    <List aria-label="caption table" dense>
      {projectUsers.length > 0 &&
        projectUsers.map((doc) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>{getInitials(doc.name)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={doc.name} secondary={doc.email} className={classes.colUser} />
            <FormControl className={classes.colRole}>
              {doc.role === Roles.OWNER.value ? (
                <Typography>{doc.role}</Typography>
              ) : (
                <Select
                  defaultValue={doc.role}
                  onChange={(e) => {
                    updateRole(doc, e.target.value);
                  }}
                >
                  {!ownerExistInProject && (
                    <MenuItem value={Roles.OWNER.value}>{Roles.OWNER.text}</MenuItem>
                  )}
                  <MenuItem value={Roles.EDITOR.value}>{Roles.EDITOR.text}</MenuItem>
                  <MenuItem value={Roles.READ_ONLY.value}>{Roles.READ_ONLY.text}</MenuItem>
                </Select>
              )}
            </FormControl>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => deleteUserFromProject(doc)}>
                <DeleteIcon fontSize="small" color="secondary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
};

ListProjectUsers.propTypes = {
  projectUsers: PropTypes.arrayOf().isRequired,
  updateRole: PropTypes.func.isRequired,
  deleteUserFromProject: PropTypes.func.isRequired,
  ownerExistInProject: PropTypes.bool.isRequired,
};

ProjectUsers.propTypes = {
  projId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProjectUsers;
