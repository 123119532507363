// Project access roles
const AccessRoles = {
  OWNER: {
    value: 'OWNER',
    text: 'Owner',
  },
  EDITOR: {
    value: 'EDITOR',
    text: 'Editor',
  },
  READ_ONLY: {
    value: 'READ_ONLY',
    text: 'Read Only',
  },
};

export default AccessRoles;
