import React from 'react';
import Main from '../../layout/Main';

const Activity = () => {
  const pageHeading = 'Activity';

  return (
    <Main pageHeading={pageHeading}>
      <h1>{pageHeading}</h1>
    </Main>
  );
};

export default Activity;
