import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import { createNewProject } from '../../services/Project';
import Validations from '../../utils/Validations';
import ProjectColors from '../../config/ProjectColors';
import projectFormStyle from '../../theme/styles/components/ProjectForm';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(projectFormStyle);

// Create a new project
const CreateProject = ({ handleNewProject, handleRefreshList }) => {
  const classes = useStyles();

  const [selectedColor, setSelectedColor] = useState(ProjectColors.COLOR1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    const saveData = {
      name: data.name,
      color: selectedColor,
    };

    createNewProject(saveData)
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Created successfully.',
          type: 'success',
          show: true,
        });
        handleRefreshList();
        setIsSubmitted(false);
      })
      .catch(({ response }) => {
        // something went wrong
        setIsSubmitted(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  const handleClose = () => {
    handleNewProject();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Create A New Project</DialogTitle>
      <DialogContent dividers>
        <form id="add-new-project" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="name"
              id="name"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  label="Project Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>

          <DialogContentText>Choose Color</DialogContentText>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.colorsContainer}
          >
            <Grid item xs={2}>
              <Button
                className={classes.btnColor1}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR1)}
              >
                {selectedColor === ProjectColors.COLOR1 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor2}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR2)}
              >
                {selectedColor === ProjectColors.COLOR2 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor3}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR3)}
              >
                {selectedColor === ProjectColors.COLOR3 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor4}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR4)}
              >
                {selectedColor === ProjectColors.COLOR4 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor5}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR5)}
              >
                {selectedColor === ProjectColors.COLOR5 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor6}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR6)}
              >
                {selectedColor === ProjectColors.COLOR6 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor7}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR7)}
              >
                {selectedColor === ProjectColors.COLOR7 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor8}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR8)}
              >
                {selectedColor === ProjectColors.COLOR8 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor9}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR9)}
              >
                {selectedColor === ProjectColors.COLOR9 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor10}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR10)}
              >
                {selectedColor === ProjectColors.COLOR10 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor11}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR11)}
              >
                {selectedColor === ProjectColors.COLOR11 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor12}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR12)}
              >
                {selectedColor === ProjectColors.COLOR12 && <CheckIcon />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="add-new-project"
          variant="contained"
          color="secondary"
          disabled={isSubmitted}
        >
          Create
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

CreateProject.propTypes = {
  handleNewProject: PropTypes.func.isRequired,
  handleRefreshList: PropTypes.func.isRequired,
};

export default CreateProject;
